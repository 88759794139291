<template>
  <Header />
  <div class="background flex flex-col pt-px">
    <div class="flex-1">
      <div class="campaign-ended relative mx-auto">
        <h1>
          <img
            class="campaign-ended__text-img w-full"
            src="/images/thanks/campaign-ended-text.webp"
            alt="キャンペーンは終了しました"
          />
        </h1>
      </div>
      <div class="participation-thanks relative mx-auto bg-white">
        <p>
          <img
            class="participation-thanks__text-img"
            src="/images/thanks/thanks-for-participation-text.webp"
            alt="ご参加ありがとうございました。"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.background {
  width: 100vw;
  background: center/200% url('/images/bg-top.webp');
}

.campaign-ended {
  width: calcMinSp(630);
  margin-top: calcMinSp(330);
}

.campaign-ended::before {
  content: '';
  display: block;
  position: absolute;
  top: calcMaxSp(-180);
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: calcMinSp(298);
  background: no-repeat center/100% url('/images/thanks/suits3.webp');
}

.participation-thanks {
  width: calcMinSp(587);
  border-radius: calcMinSp(21);
  margin-top: calcMinSp(150);
  margin-bottom: calcMinSp(220);
}

.participation-thanks::before {
  content: '';
  display: block;
  position: absolute;
  top: calcMaxSp(-180);
  left: calcMaxSp(-40);
  width: calcMinSp(256);
  height: calcMinSp(215);
  background: no-repeat center/100% url('/images/thanks/contents2-before.webp');
}

.participation-thanks::after {
  content: '';
  display: block;
  position: absolute;
  top: calcMaxSp(-100);
  right: calcMaxSp(-40);
  width: calcMinSp(133);
  height: calcMinSp(395);
  background: no-repeat center/100% url('/images/thanks/contents2-after.webp');
}

.participation-thanks__text-img {
  padding: calcMinSp(40) calcMinSp(55);
}
</style>
